import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const WindmillMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 50.4819748, lng: -3.595909 }}
    >
        {props.isMarkerShown && <Marker position={{ lat: 50.4819748, lng: -3.595909 }} />}
    </GoogleMap>
))

const LocationMap = () => {

    const apiKey = process.env.GATSBY_GOOGLE_API_KEY
    const urlBase = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
    const url = urlBase + "&key=" + apiKey

    return (<WindmillMapComponent
        isMarkerShown={false}
        googleMapURL={url}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px`, marginBottom: `40px`, marginTop: `20px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
    />)
}

export default LocationMap
