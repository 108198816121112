// extracted by mini-css-extract-plugin
export var cyan = "location-module--cyan--2LkqS";
export var cyanBg = "location-module--cyan-bg--Ikaez";
export var cyanBo = "location-module--cyan-bo--2j0FA";
export var androidGreen = "location-module--androidGreen--3U17x";
export var androidGreenBg = "location-module--androidGreen-bg--2VWZ9";
export var androidGreenBo = "location-module--androidGreen-bo--1FIgi";
export var lavender = "location-module--lavender--hJQQN";
export var lavenderBg = "location-module--lavender-bg--1Hldx";
export var lavenderBo = "location-module--lavender-bo--1Diyp";
export var corn = "location-module--corn--2tWyd";
export var cornBg = "location-module--corn-bg--3NSNf";
export var cornBo = "location-module--corn-bo--36LVA";
export var trns = "location-module--trns--379JH";
export var subHeading = "location-module--subHeading--2Czlf";