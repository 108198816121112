import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"
import LocationMap from "../components/locationMap"

import { MdAirplanemodeActive } from "react-icons/md"
import { FaTrain } from "react-icons/fa"
import { AiFillCar } from "react-icons/ai"

import * as locationStyles from "../styles/components/location.module.sass"

import ukmap from "../images/ukmap.gif"
import southwestMap from "../images/southwestMap.jpg"

const imageStyle = {
  marginBottom: `15px`,
  marginTop: `15px`
}

const LocationPage = () => (
  <Layout pageInfo={{ pageName: "Location" }}>
    <SEO title="Location" />
    <PageTitle title="Location" />
    <Container>
      <Row>
        <Col className="text-center">
          <h4 className={locationStyles.subHeading}>Longbarrow Windmill, Moles Lane, near North Whilborough, Devon, TQ3 1SB</h4>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <h6 className={locationStyles.subHeading}>Detailed directions will be sent with booking confirmation.</h6>
        </Col>
      </Row>
      <Row className="justify-content-around">
        <Col md="4" sm="12" className="blueBox">
          <h5>Local Area</h5>
          <p>The windmill is located in South Devon, near Torbay and the South Hams.</p>
          <p>Local beaches, Dartmouth, Totnes and Dartmoor are all within half an hour’s drive.</p>
        </Col>
        <Col md="4" sm="12" className="blueBox">
          <h5>Getting Here</h5>
          <p><AiFillCar /> Main road access via M4, M5 and A380</p>
          <p><FaTrain /> Mainline railway station at Newton Abbot </p>
          <p><MdAirplanemodeActive /> The closest airport is Exeter</p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md="6" sm="12" style={imageStyle}>
          <Image src={ukmap} fluid />
        </Col>
        <Col md="6" sm="12" style={imageStyle}>
          <Image src={southwestMap} fluid />
        </Col>
      </Row>
    </Container>
    {/* <LocationMap /> */}
  </Layout>
)

export default LocationPage
